<template>
	<div class="pui-form">
		<pui-form-header :showHeader="showParentModelHeader">
			<imofalformsform-header :modelPk="parentModelPk" :parentModelName="parentModelName"></imofalformsform-header>
		</pui-form-header>
		<doc-new-version-modal :modelName="modelName" :pkAttribute="pkAttribute"></doc-new-version-modal>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col xs12 lg12>
					<v-col cols="12">
						<v-row dense style="margin-top: -15px">
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
								<pui-field-set :title="$t('imofalform7.fieldset.ident')">
									<v-row dense>
										<!-- BLNUMBER -->
										<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
											<pui-text-field
												:id="`blnumber-imofalform7`"
												v-model="model.blnumber"
												:label="$t('imofalform7.blnumber')"
												:disabled="formDisabled"
												required
												toplabel
											></pui-text-field>
										</v-col>
										<!-- MARKNUMBERS -->
										<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
											<pui-text-field
												:id="`marknumbers-imofalform7`"
												v-model="model.marknumbers"
												:label="$t('imofalform7.marknumbers')"
												:disabled="formDisabled"
												required
												toplabel
												maxlength="512"
											></pui-text-field>
										</v-col>
										<!-- STOWAGEPOSITION -->
										<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
											<pui-text-field
												:id="`stowageposition-imofalform7`"
												v-model="model.stowageposition"
												:label="$t('imofalform7.stowageposition')"
												:disabled="formDisabled"
												toplabel
												maxlength="25"
											></pui-text-field>
										</v-col>
									</v-row>
								</pui-field-set>
							</v-col>
						</v-row>
						<v-row dense style="margin-top: -15px">
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
								<pui-field-set :title="$t('imofalform7.fieldset.cargo')">
									<v-row dense>
										<!-- UNNUMBER -->
										<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
											<pui-text-field
												:id="`unnumber-imofalform7`"
												v-model="model.unnumber"
												:label="$t('imofalform7.unnumber')"
												:disabled="formDisabled"
												required
												toplabel
												max="9999"
											></pui-text-field>
										</v-col>
										<!-- CLASSICODE -->
										<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
											<pui-select
												:id="`classcode-imofalform7`"
												:attach="`classcode-imofalform7`"
												:label="$t('imofalform7.classcode')"
												:disabled="formDisabled"
												required
												toplabel
												clearable
												modelName="mdgclasses"
												v-model="model"
												reactive
												:itemsToSelect="classcodeItemsToSelect"
												:modelFormMapping="{ classcode: 'classcode' }"
												:itemValue="['classcode']"
												:itemText="(item) => `${item.classcode} - ${item.classdescription} - ${item.classificationcode} `"
											></pui-select>
										</v-col>
										<!-- SUBRISKS -->
										<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
											<pui-text-field
												:id="`subrisks-imofalform7`"
												v-model="model.subrisks"
												:label="$t('imofalform7.subrisks')"
												:disabled="formDisabled"
												required
												toplabel
												maxlength="17"
											></pui-text-field>
										</v-col>
									</v-row>
									<v-row dense style="margin-top: -15px">
										<!-- TECHSPEC -->
										<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
											<pui-text-field
												:id="`techspec-imofalform7`"
												v-model="model.techspec"
												:label="$t('imofalform7.techspec')"
												:disabled="formDisabled"
												required
												toplabel
												maxlength="350"
											></pui-text-field>
										</v-col>
									</v-row>
									<v-row dense style="margin-top: -15px">
										<!-- PACKINGGROUPCODE -->
										<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
											<pui-select
												:id="`packinggroupcode-imofalform7`"
												:attach="`packinggroupcode-imofalform7`"
												:label="$t('imofalform7.packinggroupcode')"
												:disabled="formDisabled"
												toplabel
												clearable
												modelName="mpackinggroups"
												v-model="model"
												reactive
												:itemsToSelect="packinggroupcodeItemsToSelect"
												:modelFormMapping="{ code: 'packinggroupcode' }"
												:itemValue="['code']"
												:itemText="(item) => `${item.code} - ${item.groupdescription}`"
											></pui-select>
										</v-col>
										<!-- POLLUTIONCODE -->
										<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
											<pui-select
												:label="$t('imofalform7.pollutioncode')"
												itemText="text"
												itemValue="value"
												toplabel
												v-model="model"
												clearable
												reactive
												:disabled="formDisabled"
												:items="codes"
												:itemsToSelect="pollutioncodeItemsToSelect"
												:modelFormMapping="{ value: 'pollutioncode' }"
											></pui-select>
										</v-col>
										<!-- FLASHPOINT -->
										<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
											<pui-number-field
												:id="`flashpoint-imofalform7`"
												v-model="model.flashpoint"
												:label="$t('imofalform7.flashpoint')"
												:disabled="formDisabled"
												toplabel
												suffix="ºC"
											></pui-number-field>
										</v-col>
										<!-- EMS -->
										<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
											<pui-text-field
												:id="`ems-imofalform7`"
												v-model="model.ems"
												:label="$t('imofalform7.ems')"
												:disabled="formDisabled"
												toplabel
											></pui-text-field>
										</v-col>
									</v-row>
									<v-row dense style="margin-top: -15px">
										<!-- ADDITIONALINFO -->
										<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
											<pui-text-area
												:id="`additionalinfo-imofalform7`"
												v-model="model.additionalinfo"
												:label="$t('imofalform7.additionalinfo')"
												:disabled="formDisabled"
												toplabel
												maxlength="256"
											></pui-text-area>
										</v-col>
									</v-row>
									<v-row dense style="margin-top: -15px">
										<!-- PACKAGETYPECODE -->
										<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
											<pui-select
												:id="`packagetypecode-imofalform7`"
												:attach="`packagetypecode-imofalform7`"
												:label="$t('imofalform7.packagetypecode')"
												:disabled="formDisabled"
												required
												toplabel
												clearable
												modelName="mpackagetypes"
												v-model="model"
												reactive
												:itemsToSelect="packagetypecodeItemsToSelect"
												:modelFormMapping="{ packagetypecode: 'packagetypecode' }"
												:itemValue="['packagetypecode']"
												:itemText="(item) => `${item.packagetypecode} - ${item.typedescription}`"
											></pui-select>
										</v-col>
										<!-- NUMPACKAGES -->
										<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
											<pui-number-field
												:id="`numpackages-imofalform7`"
												v-model="model.numpackages"
												:label="$t('imofalform7.numpackages')"
												:disabled="formDisabled"
												required
												toplabel
											></pui-number-field>
										</v-col>
										<!-- MASS -->
										<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
											<pui-number-field
												:id="`mass-imofalform7`"
												v-model="model.mass"
												:label="$t('imofalform7.mass')"
												:disabled="formDisabled"
												required
												toplabel
												min="1"
											></pui-number-field>
										</v-col>
										<!-- MEASUREMENTUNITCODE -->
										<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
											<pui-select
												:id="`measurementunitcode-imofalform7`"
												:attach="`measurementunitcode-imofalform7`"
												:label="$t('imofalform7.measurementunitcode')"
												:disabled="formDisabled"
												required
												toplabel
												clearable
												modelName="mmeasurementunits"
												v-model="model"
												reactive
												:itemsToSelect="measurementUnitCodeItemsToSelect"
												:modelFormMapping="{ measurementunitcode: 'measurementunitcode' }"
												:itemValue="['measurementunitcode']"
												:itemText="(item) => `${item.measurementunitsymbol} -${item.typedescription}`"
											></pui-select>
										</v-col>
									</v-row>
								</pui-field-set>
							</v-col>
						</v-row>
					</v-col>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer>
				<pui-form-footer-btns
					:formDisabled="!havePermissions(['WRITE_IMOFALFORM7'])"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import imofalform7Actions from '../imofalform7menu/ImoFalForm7Actions';
import docNewVersionModal from '@/components/nsw-components/DocumentNewVersionModal';

export default {
	name: 'imofalform7-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		DocNewVersionModal: docNewVersionModal
	},
	data() {
		return {
			modelName: 'imofalform7',
			actions: imofalform7Actions.actions,
			pkAttribute: 'imofalformid',
			parentModelName: 'imofalform7menu',
			modeldetail: true,
			tabmodel: 'imofalform7',
			codes: [
				{
					text: this.$t('imofalform7.polutioncodeX'),
					value: 'X'
				},
				{
					text: this.$t('imofalform7.polutioncodeY'),
					value: 'Y'
				},
				{
					text: this.$t('imofalform7.polutioncodeZ'),
					value: 'Z'
				},
				{
					text: this.$t('imofalform7.polutioncodeOS'),
					value: 'OS'
				},
				{
					text: this.$t('imofalform7.polutioncodeP'),
					value: 'P'
				}
			]
		};
	},
	methods: {
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados
			return permissions.every((permission) => allowedForms.includes(permission));
		},
		afterGetData() {
			// Do something after get data from server

			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
			if (parentModel) {
				this.model.imofalformid = parentModel.pk.imofalformid;
			}
			if (this.$route.params.parentpk) {
				this.model.imofalformid = JSON.parse(atob(this.$route.params.pkgeneral)).imofalformid;
			}
		}
	},
	computed: {
		classcodeItemsToSelect() {
			return [{ classcode: this.model.classcode }];
		},
		packinggroupcodeItemsToSelect() {
			return [{ code: this.model.packinggroupcode }];
		},
		packagetypecodeItemsToSelect() {
			return [{ packagetypecode: this.model.packagetypecode }];
		},
		measurementUnitCodeItemsToSelect() {
			return [{ measurementunitcode: this.model.measurementunitcode }];
		},
		pollutioncodeItemsToSelect() {
			return [{ value: this.model.pollutioncode }];
		}
	}
};
</script>
