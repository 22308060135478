import printReportAction from '@/components/nsw-components/PrintReportsAction';
import DocumentNewVersionAction from '@/components/nsw-components/DocumentNewVersionAction';

export default {
	actions: [
		printReportAction.printReport('DANGEROUS_GOODS', 'imofalformid', 'reports.label.falForms.dangerousGoods'),
		DocumentNewVersionAction.newVersion({
			imofalformtypeid: 7
		})
	]
};
